import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { MD, XXL, LG } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { cloneDeep, replace } from 'lodash';
import { Message, Field } from '@zendeskgarden/react-forms';
import { Row, Col } from '@zendeskgarden/react-grid';
import Flex, { FlexItem } from 'styled-flex-component';

import { Button } from 'theme/Button';
import { FormInput, FormFooter } from 'theme/Form';
import { variables } from 'theme/variables';
import { submitContactUs, submitSubscribe } from 'state/application/actions';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import ContactUsFormTwo from './ContactUsFormTwo.styles';

const { spacing_xl: spacingXl, custom_red: customRed } = variables;

function formValidation(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

function ContactUsForm({ beta, onDone, title, displayPrompts, fullSizeForm }) {
  const submitContactForm = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const cloneValues = cloneDeep(values);
      const submitContactUsSuccess = beta
        ? await submitSubscribe(cloneValues)
        : await submitContactUs(cloneValues);
      const { error } = submitContactUsSuccess;
      if (error) {
        setStatus({ error });
      } else {
        setStatus({ error: false, success: true });
      }
      setSubmitting(false);
    },
    [beta]
  );

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
  };

  if (!beta) {
    initialValues.message = '';
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={formValidation}
      onSubmit={submitContactForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        setFieldValue,
      }) =>
        status && status.success ? (
          <div>
            <div
              style={{
                padding: '15px 40px 0',
                position: fullSizeForm ? undefined : 'absolute',
                top: '35px',
                bottom: 0,
                right: 0,
                left: 0,
                background: '#fff',
              }}
            >
              <XXL black center>
                Thank You.
              </XXL>
              {beta ? (
                <LG paddingTopMd center>
                  Stay tuned, we will update you when we have more to share.
                </LG>
              ) : (
                <LG paddingTopMd center>
                  We Will Be In Contact Shortly.
                </LG>
              )}
              {onDone ? (
                <Button onClick={onDone} center spacingTop primary>
                  Done
                </Button>
              ) : null}
            </div>
          </div>
        ) : (
          <ContactUsFormTwo
            fullSizeForm={fullSizeForm}
            size="large"
            onSubmit={handleSubmit}
          >
            {title ? (
              <LG bold className="form-title" tag="h4">
                How Can We Help?
              </LG>
            ) : null}
            {displayPrompts && (
              <LG
                navy
                style={{
                  marginBottom: '-10px',
                }}
              >
                Who would you like to speak with?
              </LG>
            )}
            {displayPrompts && (
              <LG
                navy
                style={{
                  margin: '30px 0 -10px',
                }}
              >
                Who are you?
              </LG>
            )}
            <div>
              <Row>
                <Col className="contactFormName">
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">
                      First Name
                      <MD as="span" color={customRed}>
                        {' '}
                        *
                      </MD>
                    </MD>
                    <FormInput
                      autoFocus
                      type="text"
                      id="first_name"
                      name="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      showMessage
                      error={
                        errors.first_name &&
                        touched.first_name &&
                        errors.first_name
                      }
                      placeholder="Enter First Name"
                    />
                  </FullFormFieldWrapper>
                </Col>
                <Col className="contactFormName">
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">
                      Last Name
                      <MD as="span" color={customRed}>
                        {' '}
                        *
                      </MD>
                    </MD>
                    <FormInput
                      type="text"
                      id="last_name"
                      name="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      showMessage
                      error={
                        errors.last_name &&
                        touched.last_name &&
                        errors.last_name
                      }
                      placeholder="Enter Last Name"
                    />
                  </FullFormFieldWrapper>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={6}>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">
                      Email
                      <MD as="span" color={customRed}>
                        {' '}
                        *
                      </MD>
                    </MD>
                    <FormInput
                      type="email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      showMessage
                      error={errors.email && touched.email && errors.email}
                      placeholder="Enter Email"
                    />
                  </FullFormFieldWrapper>
                </Col>
                <Col sm={12} md={6}>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">
                      Company
                      <MD as="span" color={customRed}>
                        {' '}
                        *
                      </MD>
                    </MD>
                    <FormInput
                      type="company"
                      id="company"
                      name="company"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                      showMessage
                      error={
                        errors.company && touched.company && errors.company
                      }
                      placeholder="Enter Company Name"
                    />
                  </FullFormFieldWrapper>
                </Col>
              </Row>

              <Row>
                <Col md={fullSizeForm ? 6 : 12}>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">
                      Phone Number
                    </MD>
                    <PhoneInput
                      type="phone"
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone Number"
                      country="us"
                      inputProps={{ autoComplete: 'none' }}
                      value={`${replace(values.phone || '', '++', '+')}`}
                      onChange={(phone) => {
                        setFieldValue('phone', `+${phone}`);
                      }}
                    />
                  </FullFormFieldWrapper>
                </Col>
              </Row>
              {displayPrompts && (
                <LG
                  navy
                  style={{
                    margin: '30px 0 -10px',
                  }}
                >
                  How can we help?
                </LG>
              )}
              {!beta ? (
                <Row>
                  <Col md={12}>
                    <FullFormFieldWrapper>
                      <MD bold left tag="h5">
                        Message
                        <MD as="span" color={customRed}>
                          {' '}
                          *
                        </MD>
                      </MD>
                      <FormInput
                        textarea
                        type="message"
                        id="message"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        showMessage
                        error={
                          errors.message && touched.message && errors.message
                        }
                        placeholder="Enter Message"
                      />
                    </FullFormFieldWrapper>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md={12}>
                  {status && status.error ? (
                    <FormFooter>
                      <Field>
                        <Message validation="error">{status.error}</Message>
                      </Field>
                    </FormFooter>
                  ) : null}
                  <Flex justifyCenter>
                    <FlexItem id="formSubmitButton">
                      <Button
                        loading={isSubmitting || undefined}
                        type="submit"
                        primary
                        style={{
                          marginTop: spacingXl,
                          marginBottom: '5px',
                        }}
                      >
                        Submit
                      </Button>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
            </div>
          </ContactUsFormTwo>
        )
      }
    </Formik>
  );
}

ContactUsForm.propTypes = {
  beta: PropTypes.bool,
  onDone: PropTypes.func,
  title: PropTypes.string,
  displayPrompts: PropTypes.bool,
  fullSizeForm: PropTypes.bool,
  data: PropTypes.shape({
    topic: PropTypes.string,
  }),
};

ContactUsForm.defaultProps = {
  beta: undefined,
  onDone: undefined,
  title: undefined,
  displayPrompts: undefined,
  fullSizeForm: undefined,
  data: undefined,
};

export default ContactUsForm;
