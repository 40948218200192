import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { trackClick } from 'utility/analytics';
import { useBranch } from 'baobab-react/hooks';
import { Button } from 'theme/Button';
import { SM, XXL, MD } from '@zendeskgarden/react-typography';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { confirmEmailResend } from 'state/authentication/actions';

const Pipes = styled.div`
  background-image: url(/images/Pipes.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 71%;
  width: 100%;
  opacity: 0.02;
  position: absolute;
  pointer-events: none;
  right: -102px;
  bottom: 0;
  left: auto;
  background-position: right;
  bottom: 40px;
`;

const Footer = styled.div`
  .email-link {
    padding: 10px;
    background: #eef0f3;
    text-align: center;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    a {
      color: #757575 !important;
    }
  }
`;

const Wrapper = styled.div`
  padding: 55px;
  @media (max-width: 600px) {
    padding: 40px 30px;
  }
`;

function ConfirmEmailModal({ styles, reconfirm }) {
  const [confirm, setConfirm] = useState(false);
  const [resendState, setResendState] = useState({});
  const { email } = useBranch({
    email: ['authentication', 'session', 'email'],
  });

  const confirmEmail = useCallback(() => {
    trackClick('confirmEmail', 'Confirm Now', 'ConfirmEmailModal');
    setConfirm(true);
  }, []);

  async function onResend() {
    trackClick('resendEmail', 'Confirm Now', 'ConfirmEmailModal');
    setResendState({ loading: true });
    const v = await confirmEmailResend();
    if (v.error) {
      setResendState({ loading: false });
      alert(v.error);
    } else {
      setConfirm(true);
      setResendState({ loading: false });
    }
  }

  function renderConfirmationStatus(
    _confirm,
    _reconfirm,
    _email,
    _resendState,
    _onResend,
    _confirmEmail
  ) {
    if (_confirm) {
      return (
        <div>
          <XXL paddingBottomMd style={{ maxWidth: '350px' }} bold>
            An email is on its way.
          </XXL>
          <MD slate paddingBottomSm>
            {_email}{' '}
          </MD>
          <MD style={{ padding: '10px 0 0px', maxWidth: '350px' }}>
            {' '}
            We sent you an email with instructions on how to set up your
            account. Check your inbox and get started with PipeSearch
          </MD>
        </div>
      );
    }
    if (_reconfirm) {
      return (
        <div>
          <XXL navy paddingBottomMd style={{ maxWidth: '350px' }} bold>
            Confirm Your Email
          </XXL>
          <MD slate paddingBottomSm>
            {_email}{' '}
          </MD>
          <MD navy style={{ padding: '10px 0 20px', maxWidth: '350px' }}>
            {' '}
            We sent you an email with instructions on how to set up your
            account. Check your inbox and get started with PipeSearch
          </MD>
          <SM bold paddingTopSm navy>
            If You Did Not Receive An Email.{' '}
            <SM
              as="span"
              onClick={_resendState.loading ? null : _onResend}
              link
            >
              Click Here.
            </SM>
          </SM>
        </div>
      );
    }
    return (
      <div>
        <XXL navy paddingBottomMd style={{ maxWidth: '350px' }} bold>
          Please Confirm Your Email
        </XXL>
        <MD slate paddingBottomSm>
          {_email}{' '}
        </MD>
        <MD style={{ padding: '10px 0 20px', maxWidth: '350px' }}>
          Before you can begin using your account to the fullest, you must
          verify the authenticity of your email address.
        </MD>
        <Button spacingTop onClick={_confirmEmail} primary>
          Confirm Now
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        position: 'relative',
        maxWidth: '800px',
        ...(styles || {}),
      }}
    >
      <Wrapper>
        {renderConfirmationStatus(
          confirm,
          reconfirm,
          email,
          resendState,
          onResend,
          confirmEmail
        )}
      </Wrapper>
      <Pipes />
      <Footer>
        <MD left className="email-link" tag="p">
          <a href="mailto:sales@pipesearch.com">
            Email Us: sales@pipesearch.com
          </a>
        </MD>
      </Footer>
    </div>
  );
}

ConfirmEmailModal.propTypes = {
  styles: PropTypes.shape({}),
  reconfirm: PropTypes.bool,
};

ConfirmEmailModal.defaultProps = {
  styles: {},
  reconfirm: false,
};

export default PageViewWrapper(ConfirmEmailModal, {
  pageType: 'modal',
  pageName: 'ConfirmEmailModal',
});
