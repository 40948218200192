import React, { useCallback, useState, useEffect } from 'react';
import { SM, MD } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Field, Textarea } from '@zendeskgarden/react-forms';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { Button } from 'theme/Button';
import { map, isEmpty, filter } from 'lodash';
import { variables } from 'theme/variables';
import { formProps } from 'propTypesObjects';
import DatePicker from 'components/DatePicker/DatePicker';
import GoogleLocationLookup from 'components/GoogleLocationLookup/GoogleLocationLookup';
import {
  transformFromBEtoForm,
  formatTags,
} from 'state/marketComparables/transformer';
import { PropTypes } from 'prop-types';
import { getRole } from 'utility/hasAccount';
import DropZoneFileManager from 'components/DropZone/DropZoneFileManager';
import { useBranch } from 'baobab-react/hooks';
import CompanyLookupV2 from 'components/CompanyLookup/CompanyLookupV2';
import FilterItemValues from 'components/FilterItemGroup/components/FilterItemValues/FilterItemValues';
import { FormCheckbox } from 'components/Checkbox/Checkbox';
import FilterItemGroup from 'components/FilterItemGroup/FilterItemGroup';
import { getRfqMeta } from 'state/requestForQuote/actions';
import FilterItemInputs from '../FilterItemGroup/components/FilterItemInputs/FilterItemInputs';
import { setMarketComparable } from '../../state/marketComparables/actions';
import MultiTypeForm from '../MultiTypeForm/MultiTypeForm';

const { custom_red: customRed } = variables;

function MarketCompForm({
  disabled,
  marketCompId,
  canContinue,
  data,
  onSuccess,
  tab,
  type,
  changeTab,
}) {
  const { value: role } = getRole() || {};
  const { clientId, rfqMeta } = useBranch({
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
    rfqMeta: ['requestForQuote', 'meta', 'result'],
  });
  const [localMarketCompId, setLocalMarketCompId] = useState(
    marketCompId || data?.id || null
  );
  const [initialFields, setInitialFields] = useState({});

  useEffect(() => {
    if (isEmpty(rfqMeta)) {
      getRfqMeta();
    }
  }, [rfqMeta]);

  useEffect(() => {
    async function updateFields() {
      const formFields = await transformFromBEtoForm(tab, type);
      await setInitialFields(formFields);
    }
    updateFields();
  }, [type, tab]);

  const salesTypes = [
    { id: 1, name: 'Stock' },
    { id: 2, name: 'Mill' },
    { id: 3, name: 'Auction' },
  ];

  const submitMarketComp = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      if (!isEmpty(values)) {
        /* 
          If we send a PATCH request without 'tag', the tags will be removed from the Market Comp (this is a
          backend issue). So we preserve the existing tags here, and because the existing tags in 'data' 
          aren't in the same shape as the 'values' tags, we handle that normalization here.
        */
        const existingTags = data.tags;
        const { tag: tagFromValues, ...remainingValues } = values;
        const modifiedValues = remainingValues;
        const tag = tagFromValues ? map(tagFromValues, 'value') : existingTags;
        setStatus({}); // This is to clear any errors from previous submissions
        setSubmitting(true);
        const saveMarketCompResult =
          type === 'Edit' || (type === 'Add' && !disabled)
            ? await setMarketComparable(
                { tag, ...modifiedValues },
                'Edit',
                localMarketCompId
              )
            : await setMarketComparable({ tag, ...modifiedValues }, type);
        const { error } = saveMarketCompResult;
        if (error) {
          setStatus({ error });
        } else {
          setLocalMarketCompId(saveMarketCompResult.id);
          if (onSuccess && type === 'Edit') {
            onSuccess({ type: 'edit' });
          } else {
            onSuccess({ type: 'add' });
          }
        }
        setSubmitting(false);
      } else if (onSuccess && type === 'Edit') {
        onSuccess({ type: 'edit' });
      } else {
        onSuccess({ type: 'add' });
      }
    },
    [data.tags, disabled, localMarketCompId, onSuccess, type]
  );

  return (
    <Formik
      initialValues={initialFields}
      onSubmit={(values, actions) => submitMarketComp(values, actions)}
    >
      {(props) => (
        <FormRendered
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          tab={tab}
          marketCompId={marketCompId || localMarketCompId}
          setFieldValue={props?.setFieldValue}
          values={props?.values}
          salesTypes={salesTypes}
          disabled={disabled}
          canContinue={canContinue}
          initialFields={initialFields}
          clientId={clientId}
          changeTab={changeTab}
          role={role}
          rfqMeta={rfqMeta}
          rawData={data}
          type={type}
        />
      )}
    </Formik>
  );
}

MarketCompForm.defaultProps = {
  onDelete: undefined,
  disabled: undefined,
  engagementId: undefined,
  canContinue: undefined,
  data: {},
  onSuccess: undefined,
  companyId: undefined,
  tab: 'overview',
};

MarketCompForm.propTypes = {
  ...formProps.generalForm.propTypes,
};

function FormRendered({
  values,
  handleSubmit,
  status,
  setFieldValue,
  salesTypes,
  tab,
  initialFields,
  changeTab,
  clientId,
  rfqMeta,
  rawData,
  type,
  marketCompId,
  disabled,
}) {
  const getTagData = useCallback(async () => {
    const originalTag = initialFields?.tag || {};
    const formattedRawTags = await formatTags(rawData.tags);
    if (originalTag?.fields?.tag) {
      originalTag.fields.tag.value =
        values?.tag || initialFields?.tag?.value || formattedRawTags || [];
    }
    return { tag: originalTag };
  }, [initialFields?.tag, rawData.tags, values?.tag]);

  const [tagData, setTagData] = useState({});

  useEffect(() => {
    async function waitForTagData() {
      const response = await getTagData();
      setTagData(response);
    }
    waitForTagData();
  }, [getTagData]);

  function renderForm() {
    if (tab === 'description') {
      return (
        <>
          <MD bold>Material Specifications</MD>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.category?.title}
                </SM>
                <FilterItemValues
                  medium
                  persistCasing
                  defaultValue={initialFields?.category?.defaultValue}
                  error={false}
                  optionValues={initialFields?.category?.items}
                  selectedValue={
                    values?.category || initialFields?.category?.value
                  }
                  setSelectedValue={(value) => {
                    setFieldValue('category', value);
                  }}
                  active={values?.category !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.od?.title}
                </SM>
                <FilterItemValues
                  medium
                  error={false}
                  customValues
                  defaultValue={initialFields?.od?.defaultValue}
                  optionValues={initialFields?.od?.items}
                  selectedValue={values?.od || initialFields?.od?.value || {}}
                  setSelectedValue={(value) => {
                    setFieldValue('od', value);
                  }}
                  active={values?.od !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.weight_per_foot?.title}
                </SM>
                <FilterItemValues
                  medium
                  error={false}
                  customValues
                  defaultValue={initialFields?.weight_per_foot?.defaultValue}
                  optionValues={
                    values?.od && !values?.od?.customValue
                      ? filter(
                          initialFields?.weight_per_foot?.items,
                          (item) => item?.standard_od === values.od.id
                        )
                      : initialFields?.weight_per_foot?.items
                  }
                  selectedValue={
                    values?.weight_per_foot ||
                    initialFields?.weight_per_foot?.value ||
                    {}
                  }
                  setSelectedValue={(value) => {
                    setFieldValue('weight_per_foot', value);
                  }}
                  active={values?.weight_per_foot !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.min_yield?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.min_yield?.defaultValue}
                  value={
                    values?.min_yield
                      ? values?.min_yield
                      : initialFields?.min_yield?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('min_yield', value);
                  }}
                  uom="KSI"
                  active={values?.min_yield !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.alloy?.title}
                </SM>
                <FilterItemGroup
                  ignorePlaceholderPrefix
                  filter={
                    values?.alloy
                      ? { ...initialFields?.alloy, value: values.alloy }
                      : initialFields?.alloy || {}
                  }
                  selectionFilters={
                    initialFields?.alloy?.selectionFilters || {}
                  }
                  currentFilters={
                    values?.alloy
                      ? {
                          ...initialFields,
                          alloy: {
                            ...initialFields.alloy,
                            value: values.alloy,
                          },
                        }
                      : initialFields
                  }
                  type="alloy"
                  onChangeFilters={(key, field, data) => {
                    setFieldValue(`${field}`, data?.value);
                  }}
                  rfqMeta={rfqMeta}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.end_finish?.title}
                </SM>
                <FilterItemGroup
                  multiple
                  ignorePlaceholderPrefix
                  filter={
                    values?.end_finish
                      ? {
                          ...initialFields?.end_finish,
                          value: values.end_finish,
                        }
                      : initialFields?.end_finish || {}
                  }
                  currentFilters={
                    values?.end_finish
                      ? {
                          ...initialFields,
                          end_finish: {
                            ...initialFields.end_finish,
                            value: values.end_finish,
                          },
                        }
                      : initialFields
                  }
                  selectionFilters={
                    initialFields?.end_finish?.selectionFilters || {}
                  }
                  type="end_finish"
                  onChangeFilters={(_type, field, data) => {
                    setFieldValue(`${field}`, data?.value);
                  }}
                  rfqMeta={rfqMeta}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.condition?.title}
                </SM>
                <FilterItemValues
                  medium
                  error={false}
                  defaultValue={initialFields?.condition?.defaultValue}
                  optionValues={initialFields?.condition?.items}
                  selectedValue={
                    values?.condition || initialFields?.condition?.value || {}
                  }
                  setSelectedValue={(value) => {
                    setFieldValue('condition', value);
                  }}
                  active={values?.condition !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <MD bold>Mill Information</MD>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.manufacturer?.required && (
                    <span style={{ color: customRed }}>* </span>
                  )}
                  {initialFields?.manufacturer?.title}
                </SM>
                <FilterItemValues
                  medium
                  error={false}
                  defaultValue={initialFields?.manufacturer?.defaultValue}
                  optionValues={initialFields?.manufacturer?.items}
                  selectedValue={
                    values.manufacturer || initialFields?.manufacturer?.value
                  }
                  setSelectedValue={(value) => {
                    setFieldValue('manufacturer', value);
                  }}
                  active={values?.manufacturer !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.mill_year?.required && (
                    <span style={{ color: customRed }}>* </span>
                  )}
                  {initialFields?.mill_year?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.mill_year?.defaultValue}
                  value={
                    values?.mill_year
                      ? values?.mill_year
                      : initialFields?.mill_year?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('mill_year', value);
                  }}
                  active={values?.mill_year !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="12" justifyCenter alignCenter>
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.mtr?.title}
                </SM>
                <FormCheckbox
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  onChange={() => {
                    setFieldValue(
                      'mtr.value',
                      !isEmpty(values?.mtr)
                        ? !values?.mtr?.value
                        : !initialFields?.mtr?.value?.value
                    );
                  }}
                  name="mtr"
                  checked={
                    !isEmpty(values?.mtr)
                      ? !!values?.mtr?.value
                      : !!initialFields?.mtr?.value?.value
                  }
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row justifyEvenly style={{ marginTop: 20 }}>
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('overview')}
              >
                Back
              </Button>
            </Col>
            {type === 'Edit' && (
              <Col
                alignCenter
                justifyCenter
                style={{ flexWrap: 'wrap', flexDirection: 'column' }}
              >
                {status && status.error ? (
                  <div>
                    <SM error validation="error">
                      {status.error}
                    </SM>
                  </div>
                ) : null}
                <Button
                  buttonStatus
                  primary
                  style={{ marginTop: '20px', marginBottom: 0 }}
                  onClick={() => handleSubmit()}
                >
                  Save & Close
                </Button>
              </Col>
            )}
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              {status && status.error && type !== 'Edit' ? (
                <div>
                  <SM error validation="error">
                    {status.error}
                  </SM>
                </div>
              ) : null}
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('quantity')}
              >
                Next
              </Button>
            </Col>
          </Row>
        </>
      );
    }
    if (tab === 'quantity') {
      return (
        <>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.footage?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.footage?.defaultValue}
                  value={
                    values?.footage
                      ? values?.footage
                      : initialFields?.footage?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('footage', value);
                  }}
                  uom={initialFields?.footage?.uom}
                  active={values?.footage !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.meters?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.meters?.defaultValue}
                  value={
                    values?.meters
                      ? values?.meters
                      : initialFields?.meters?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('meters', value);
                  }}
                  uom={initialFields?.meters?.uom}
                  active={values?.meters !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.inches?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.inches?.defaultValue}
                  value={
                    values?.inches
                      ? values?.inches
                      : initialFields?.inches?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('inches', value);
                  }}
                  uom={initialFields?.inches?.uom}
                  active={values?.inches !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.joints?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.joints?.defaultValue}
                  value={
                    values?.joints
                      ? values?.joints
                      : initialFields?.joints?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('joints', value);
                  }}
                  uom={initialFields?.joints?.uom}
                  active={values?.joints !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.lbs?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.lbs?.defaultValue}
                  value={values?.lbs ? values?.lbs : initialFields?.lbs?.value}
                  onChangeInput={(value) => {
                    setFieldValue('lbs', value);
                  }}
                  uom={initialFields?.lbs?.uom}
                  active={values?.lbs !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row justifyEvenly style={{ marginTop: 20 }}>
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('description')}
              >
                Back
              </Button>
            </Col>
            {type === 'Edit' && (
              <Col
                alignCenter
                justifyCenter
                style={{ flexWrap: 'wrap', flexDirection: 'column' }}
              >
                {status && status.error ? (
                  <div>
                    <SM error validation="error">
                      {status.error}
                    </SM>
                  </div>
                ) : null}
                <Button
                  buttonStatus
                  primary
                  style={{ marginTop: '20px', marginBottom: 0 }}
                  onClick={() => handleSubmit()}
                >
                  Save & Close
                </Button>
              </Col>
            )}
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              {status && status.error && type !== 'Edit' ? (
                <div>
                  <SM error validation="error">
                    {status.error}
                  </SM>
                </div>
              ) : null}
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('pricing')}
              >
                Next
              </Button>
            </Col>
          </Row>
        </>
      );
    }
    if (tab === 'pricing') {
      return (
        <>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.price_per_foot?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.price_per_foot?.defaultValue}
                  value={
                    values?.price_per_foot
                      ? values?.price_per_foot
                      : initialFields?.price_per_foot?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('price_per_foot', value);
                  }}
                  uom={initialFields?.price_per_foot?.uom}
                  active={values?.price_per_foot !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.price_per_meter?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.price_per_meter?.defaultValue}
                  value={
                    values?.price_per_meter
                      ? values?.price_per_meter
                      : initialFields?.price_per_meter?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('price_per_meter', value);
                  }}
                  uom={initialFields?.price_per_meter?.uom}
                  active={values?.price_per_meter !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.price_per_inch?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.price_per_inch?.defaultValue}
                  value={
                    values?.price_per_inch
                      ? values?.price_per_inch
                      : initialFields?.price_per_inch?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('price_per_inch', value);
                  }}
                  uom={initialFields?.price_per_inch?.uom}
                  active={values?.price_per_inch !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.price_per_joint?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.price_per_joint?.defaultValue}
                  value={
                    values?.price_per_joint
                      ? values?.price_per_joint
                      : initialFields?.price_per_joint?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('price_per_joint', value);
                  }}
                  uom={initialFields?.price_per_joint?.uom}
                  active={values?.price_per_joint !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.price_per_lb?.title}
                </SM>
                <FilterItemInputs
                  style={{ marginTop: 10 }}
                  placeholder={initialFields?.price_per_lb?.defaultValue}
                  value={
                    values?.price_per_lb
                      ? values?.price_per_lb
                      : initialFields?.price_per_lb?.value
                  }
                  onChangeInput={(value) => {
                    setFieldValue('price_per_lb', value);
                  }}
                  uom={initialFields?.price_per_lb?.uom}
                  active={values?.price_per_lb !== ''}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row justifyEvenly style={{ marginTop: 20 }}>
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('quantity')}
              >
                Back
              </Button>
            </Col>
            {type === 'Edit' && (
              <Col
                alignCenter
                justifyCenter
                style={{ flexWrap: 'wrap', flexDirection: 'column' }}
              >
                {status && status.error ? (
                  <div>
                    <SM error validation="error">
                      {status.error}
                    </SM>
                  </div>
                ) : null}
                <Button
                  buttonStatus
                  primary
                  style={{ marginTop: '20px', marginBottom: 0 }}
                  onClick={() => handleSubmit()}
                >
                  Save & Close
                </Button>
              </Col>
            )}
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              {status && status.error && type !== 'Edit' ? (
                <div>
                  <SM error validation="error">
                    {status.error}
                  </SM>
                </div>
              ) : null}
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('personnel')}
              >
                Next
              </Button>
            </Col>
          </Row>
        </>
      );
    }
    if (tab === 'personnel') {
      return (
        <>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.supplier?.title}
                </SM>
                <CompanyLookupV2
                  searchType="company"
                  placeholder={initialFields?.supplier?.defaultValue}
                  clientId={clientId}
                  style={{ marginTop: 10 }}
                  selectedItem={
                    values.supplier || initialFields?.supplier?.value
                  }
                  setSelectedValue={(value) => {
                    setFieldValue('supplier', value);
                  }}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.contact?.title}
                </SM>
                <CompanyLookupV2
                  searchType="contact"
                  placeholder={initialFields?.contact?.defaultValue}
                  clientId={clientId}
                  style={{ marginTop: 10 }}
                  selectedItem={values.contact || initialFields?.contact?.value}
                  setSelectedValue={(value) => {
                    setFieldValue('contact', value);
                  }}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="div" style={{ marginBottom: 10 }}>
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.buyer?.title}
                </SM>
                <CompanyLookupV2
                  searchType="company"
                  placeholder={initialFields?.buyer?.defaultValue}
                  clientId={clientId}
                  style={{ marginTop: 10 }}
                  selectedItem={values.buyer || initialFields?.buyer?.value}
                  setSelectedValue={(value) => {
                    setFieldValue('buyer', value);
                  }}
                />
              </FullFormFieldWrapper>
            </Col>
            <Col sm="12" lg="6">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="div" style={{ marginBottom: 10 }}>
                  <span style={{ color: customRed }}>* </span>
                  {initialFields?.end_user?.title}
                </SM>
                <CompanyLookupV2
                  searchType="company"
                  placeholder={initialFields?.end_user?.defaultValue}
                  clientId={clientId}
                  style={{ marginTop: 10 }}
                  selectedItem={
                    values.end_user || initialFields?.end_user?.value
                  }
                  setSelectedValue={(value) => {
                    setFieldValue('end_user', value);
                  }}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row justifyEvenly style={{ marginTop: 20 }}>
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => changeTab('pricing')}
              >
                Back
              </Button>
            </Col>
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              {status && status.error ? (
                <div>
                  <SM error validation="error">
                    {status.error}
                  </SM>
                </div>
              ) : null}
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                type="submit"
              >
                {`${disabled ? 'Save Market Comparable' : 'Save & Close'}`}
              </Button>
            </Col>
            {!disabled && (
              <Col
                alignCenter
                justifyCenter
                style={{ flexWrap: 'wrap', flexDirection: 'column' }}
              >
                {status && status.error && type !== 'Edit' ? (
                  <div>
                    <SM error validation="error">
                      {status.error}
                    </SM>
                  </div>
                ) : null}
                <Button
                  buttonStatus
                  primary
                  style={{ marginTop: '20px', marginBottom: 0 }}
                  onClick={() => changeTab('documents')}
                >
                  Next
                </Button>
              </Col>
            )}
          </Row>
        </>
      );
    }
    if (tab === 'documents') {
      return (
        <>
          <Row>
            <Col size="12">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.files?.title}
                </SM>
                <DropZoneFileManager
                  type="upload"
                  uploadOnDrop
                  showUploadAndRender
                  isImage={false}
                  hidePreviousUploads
                  objectId={marketCompId}
                  contentType={rfqMeta?.file_content_types?.MarketComps}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.images?.title}
                </SM>
                <DropZoneFileManager
                  onClickThumbnail={(x) =>
                    window.open(x.data.image, '', 'width=900,height=700')
                  }
                  type="upload"
                  isImage
                  multiple
                  uploadOnDrop
                  showUploadAndRender
                  hidePreviousUploads
                  objectId={marketCompId}
                  contentType={rfqMeta?.file_content_types?.MarketComps}
                />
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <FullFormFieldWrapper spacer spacing="0px">
                <SM bold navy scaled tag="span">
                  {initialFields?.comments?.title}
                </SM>
                <Field>
                  <Textarea
                    autoFocus
                    style={{ minHeight: '100px', width: '100%' }}
                    placeholder="Enter comments about this market comparable. "
                    value={
                      'comments' in values
                        ? values.comments
                        : initialFields?.comments?.value || ''
                    }
                    id="market-comp-comments"
                    name="comments"
                    onChange={(value) =>
                      setFieldValue('comments', value.currentTarget.value)
                    }
                  />
                </Field>
              </FullFormFieldWrapper>
            </Col>
          </Row>
          <Row justifyEvenly style={{ marginTop: 20 }}>
            <Col alignCenter justifyCenter>
              <Button
                buttonStatus
                primary
                style={{ marginTop: '0px', marginBottom: 0 }}
                onClick={() => changeTab('personnel')}
              >
                Back
              </Button>
            </Col>
            <Col alignCenter justifyCenter>
              {status && status.error ? (
                <div>
                  <SM error validation="error">
                    {status.error}
                  </SM>
                </div>
              ) : null}
              <Button
                buttonStatus
                primary
                style={{ marginTop: '0px', marginBottom: 0 }}
                type="submit"
              >
                Save Market Comparable
              </Button>
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        <Row>
          <Col sm="12" lg="6">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="span">
                <span style={{ color: customRed }}>* </span>
                {initialFields?.type?.title}
              </SM>
              <FilterItemValues
                medium
                style={{ marginTop: '20px' }}
                error={false}
                defaultValue={initialFields?.type?.defaultValue}
                optionValues={map(salesTypes, (item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                selectedValue={values?.type || initialFields?.type?.value || ''}
                setSelectedValue={(value) => {
                  setFieldValue('type', value);
                }}
                active={values?.type !== ''}
              />
            </FullFormFieldWrapper>
          </Col>
          <Col sm="12" lg="6">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="span">
                {initialFields?.employee?.title}
              </SM>
              <CompanyLookupV2
                searchType="user"
                valueKey="employee_id"
                placeholder={initialFields?.employee?.defaultValue}
                clientId={clientId}
                style={{ marginTop: 10 }}
                selectedItem={values.employee || initialFields?.employee?.value}
                setSelectedValue={(value) => {
                  setFieldValue('employee', value);
                }}
              />
            </FullFormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="div" style={{ marginBottom: 10 }}>
                {initialFields?.received_date?.title}
              </SM>
              <DatePicker
                validation={null}
                date={
                  values?.received_date ||
                  initialFields?.received_date?.value?.value ||
                  ''
                }
                medium
                onChange={(value) => {
                  setFieldValue('received_date', value);
                }}
              />
            </FullFormFieldWrapper>
          </Col>
          <Col sm="12" lg="6">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="div" style={{ marginBottom: 10 }}>
                {initialFields?.transaction_date?.title}
              </SM>
              <DatePicker
                validation={null}
                date={
                  values?.transaction_date ||
                  initialFields?.transaction_date?.value?.value ||
                  ''
                }
                medium
                onChange={(value) => {
                  setFieldValue('transaction_date', value);
                }}
              />
            </FullFormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col size="12">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="div" style={{ marginBottom: 10 }}>
                {initialFields?.location_address?.title}
              </SM>
              <GoogleLocationLookup
                medium
                selectedValue={
                  values?.location_address?.value ||
                  initialFields?.location_address?.value?.value ||
                  ''
                }
                value={
                  values?.location_address?.value ||
                  initialFields?.location_address?.value?.value ||
                  ''
                }
                defaultValue={initialFields?.location_address?.defaultValue}
                setSelectedValue={(value) => {
                  setFieldValue('location_address', value);
                }}
              />
            </FullFormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col size="12">
            <FullFormFieldWrapper spacer spacing="0px">
              <MultiTypeForm
                ignoreHeader
                data={tagData}
                setData={(cat, _type, val) => {
                  setFieldValue('tag', val.value);
                }}
                contentType={rfqMeta.attribute_content_types.MarketComps}
              />
            </FullFormFieldWrapper>
          </Col>
        </Row>
        <Row justifyBetween style={{ marginTop: 20 }}>
          {type === 'Edit' && (
            <Col
              alignCenter
              justifyCenter
              style={{ flexWrap: 'wrap', flexDirection: 'column' }}
            >
              {status && status.error ? (
                <div>
                  <SM error validation="error">
                    {status.error}
                  </SM>
                </div>
              ) : null}
              <Button
                buttonStatus
                primary
                style={{ marginTop: '20px', marginBottom: 0 }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save & Close
              </Button>
            </Col>
          )}
          <Col
            alignCenter
            justifyCenter
            style={{ flexWrap: 'wrap', flexDirection: 'column' }}
          >
            {status && status.error && type !== 'Edit' ? (
              <div>
                <SM error validation="error">
                  {status.error}
                </SM>
              </div>
            ) : null}
            <Button
              buttonStatus
              primary
              style={{ marginTop: '20px', marginBottom: 0 }}
              onClick={() => changeTab('description')}
            >
              Next
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Form size="large" onSubmit={handleSubmit}>
      {renderForm()}
    </Form>
  );
}

FormRendered.defaultProps = {
  initialFields: undefined,
  clientId: undefined,
};

FormRendered.propTypes = {
  ...formProps.formRendered.propTypes,
  ...formProps.generalForm.propTypes,
  initialFields: PropTypes.shape({}),
  clientId: PropTypes.number,
};

export default MarketCompForm;
